@import '../../../01_ASSETS/global-values/global-values.scss';

.offcanvas-special-open-hours {
  background-color: rgb(232, 232, 80,);
  padding: 0 12px;

  .offcanvas-header{
    color: black;
    font-family: $main-font;
  }

  .special-open-hours {
    overflow: hidden;
    font-family: $second-font;
    font-size: 14px;
 
    .open-hours {
      font-weight: bold;
    }
    
    .red {
      color: red;
    }
  }
}

// DESKTOP STYLES //

@media (min-width: 768px) {
  .offcanvas-special-open-hours{
    max-width: 700px;
    margin: 0 auto;
    height: 300px !important;

    border: 1px solid $main-color !important;
    border-top-left-radius: 350px 20px !important;
    border-top-right-radius: 150px 25px !important;
    border-bottom-right-radius: 250px 0px !important;
    border-bottom-left-radius: 0px 350px !important;
    box-shadow: 10px 20px 30px -10px rgba(0, 0, 128, 0.2);
  }

  .special-open-hours  {
    font-size: 16px;
    padding-bottom: 40px !important;
  }
}
