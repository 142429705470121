@import '../../01_ASSETS/global-values/global-values.scss';

.Navbar {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 9999;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 99.98%, rgba(0, 0, 0, 0.00) 99.99%, rgba(0, 0, 0, 0.00) 100%) !important;
  padding-bottom: 80px;
  
  .navbar-logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    transition: margin 0.3s ease-out;
  }
  
  .navbar-logo-wrapper {
    display: flex;
    align-items: center;
  
    .navbar-logo {
      margin: 0 auto;
      transition: all 0.3s ease-in-out;
    }
  }
  
  
  .navbar-toggler {
    position: absolute;
    right: 20px;
    top: 40px;
    transform: translateY(-50%);
    border: none;
    font-size: 24px;
    color: $second-color;
    padding: 0;

    &:hover {
      color: $accent-color;
      cursor: pointer;
    }
  }
}

.offcanvas-menu {
  height: 100vh;
  background: $main-color;
  z-index: 10000;

  .offcanvas-close {
    position: absolute;
    right: 20px;
    top: 35px;
    font-size: 24px;
    color: $second-color;
    transition: all 0.25s ease;
  
    &:hover {
      color: $accent-color;
      transform: rotate(180deg);
    }
  }
  
  .menu-header {
    font-family: $main-font;
    font-size: 36px;
    margin-left: 10px;
    color: white;
  }

  .navlink {
    color: $accent-color;
    font-family: $main-font;
    font-size: 18px;
    font-weight: 100;
    transition: all 0.5s ease;

    height: 45px;
  
    &.active {
      color: $accent-color;
      font-size: 20px;
  
      .nav-icon {
        color: $accent-color;
        font-size: 20px;
      }
    }
  
    &.inactive {
      color: rgb(205, 202, 202);
      font-size: 18px;
      transition: all 0.5s ease;
  
      &:hover {
        font-size: 20px;
        color: $second-color;
      }
    }
  
    .nav-icon {
      margin: 0px 10px 0px 0px;
      width: 15px;
    }
  }
}
