@import './01_ASSETS/global-values/global-values.scss';

* {
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background-color: black;
  overflow-x: hidden;
}

