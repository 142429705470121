@import '../../01_ASSETS/global-values/global-values.scss';

.footer-container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 0, 0, 0.81) 86.46%);
  padding: 80px 20px 20px 20px;
  z-index: 999;
  overflow: hidden;
}

// ICONS //

.footer-icons {
  display: flex;
  justify-content: space-between;
  width: 170px;
  color: $second-color;

  .icon {
    font-size: 20px;
  }

  .facebook {
    color: $second-color;
    transition: color 0.5s ease;
    &:hover{
      color: #1973eb;
    }
  }

  .instagram {
    color: $second-color;
    transition: color 0.5s ease;
    &:hover{
      color: #ed0517;
      
    }
  }

  .map-icon {
    align-self: last baseline !important;
    transition: color 0.5s ease;
    &:hover{
      color: $accent-color;
      cursor: pointer;
    }
  }

  .icon.map-icon:focus {
    outline: none;
  }

  .phone-icon {
    color: $second-color;
    transition: color 0.5s ease;
    &:hover{
      color: $accent-color;
    }
  }
}

// OFFCANVAS //

.menu-header {
  font-size: 30px;
  font-family: $main-font;
}

.menu-header-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

}

// MAP //

.offcanvas-map {
  background-color: rgba(0,0,0,.85);
  color: $second-color;
  font-family: $second-font;
  margin: 0 auto;
  max-width: 600px;
  border: none !important;

  .google-map {
    height: 100%;
    width: 100%;
  }

  .store-map {
    height: 80%; 
    width: 100%;
  }
}

// OPEN HOURS //

.footer-btn {
  font-family: $main-font;
  color: $second-color;
  transition: background-color 0.5s ease;

  &:hover {
    color: $accent-color;
    background-color: $main-color;
    border: 1px solid $accent-color;
  }
}

.offcanvas-open-hours {
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 0 12px;
  background-color: rgba(0,0,0,.85);
  color: $second-color;
  font-family: $main-font;
  border: none !important;

  
  .offcanvas-body {
    font-family: $second-font;
    font-size: 14px;
    overflow: hidden;
  }
  .open-hours {
    font-weight: bold;
  }
  
  .red {
    color: red;
  }
}

.special-hours-btn {
  font-family: $main-font;
  font-size: 12px;
  background-color: $main-color;
  color: $second-color;
  border-radius: 0;
  transition: all 0.5s ease !important;
  
  .icon {
    font-size: 12px;
    margin-right: 7px;
    color: $accent-color;
  }

  &:hover {
    color: $accent-color;
    background-color: $main-color;
    border: 1px solid $accent-color;
  }
}

// DESKTOP STYLES //

@media (min-width: 768px) {
  .offcanvas-open-hours{
    background-color: rgba(0,0,0,1);
    max-width: 700px;
    margin: 0 auto;
    height: 300px !important;
  }

  .offcanvas-map {
    background-color: rgba(0,0,0,1);
    max-width: 700px;
    margin: 0 auto;
    height: 550px !important;
  }

  .offcanvas-open-hours .offcanvas-body {
    font-size: 16px;
    padding-bottom: 40px !important;
  }
}
