// Colors //
$main-color: Black;
$second-color: White;
$theme-color: #f7f7f7;
$accent-color:#f4f495;
$info-color: blue;
$light-color: grey;
$dark-color: Darkgrey;

// Fonts //
@font-face {
  font-family: 'Roboto Serif';
  src: url('./fonts/Roboto-Serif/RobotoSerif-VariableFont_GRAD,opsz,wdth,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'PermanentMarker';
  src: url('./fonts/Permanent-Marker/PermanentMarker-Regular.ttf') format('truetype');
}

$main-font: 'PermanentMarker';
$second-font: 'Inter';
$accent-font: 'Sans-Serif';
$info-font:  'PermanentMarker';

// BORDER STYLES LOGS// 
.border-style-1 {
  border: 1px solid $main-color !important;
  border-top-left-radius: 600px 50px !important;
  border-top-right-radius: 30px 200px !important;
  border-bottom-right-radius: 100px 30px !important;
  border-bottom-left-radius: 30px 300px !important;
  box-shadow: 10px 20px 30px -10px rgba(165, 42, 42, 0.2);
}

.border-style-2 {
  border: 1px solid $main-color !important;
  border-top-left-radius: 350px 20px !important;
  border-top-right-radius: 150px 25px !important;
  border-bottom-right-radius: 250px 0px !important;
  border-bottom-left-radius: 0px 350px !important;
  box-shadow: 10px 20px 30px -10px rgba(0, 0, 128, 0.2);
}

.border-style-3 {
  border: 1px solid $main-color !important;
  border-top-left-radius: 400px 20px !important;
  border-top-right-radius: 20px 220px !important;
  border-bottom-right-radius: 220px 20px !important;
  border-bottom-left-radius: 20px 280px !important;
  box-shadow: 10px 20px 30px -10px rgba(0, 128, 0, 0.2);
}

.border-style-4 {
  border: 1px solid $main-color !important;
  border-top-left-radius: 370px 25px !important;
  border-top-right-radius: 5px 230px !important;
  border-bottom-right-radius: 230px 45px !important;
  border-bottom-left-radius: 14px 420px !important;
  box-shadow: 10px 20px 30px -10px rgba(255, 215, 0, 0.2);
}

.border-style-5 {
  border: 1px solid $main-color !important;
  border-top-left-radius: 380px 40px !important;
  border-top-right-radius: 30px 110px !important;
  border-bottom-right-radius: 210px 40px !important;
  border-bottom-left-radius: 40px 80px !important;
  box-shadow: 10px 20px 30px -10px rgba(255, 69, 0, 0.2);
}

// OTHER BORDER STYLES //

.border-style-button {
  border: 1px solid $second-color;
  border-top-left-radius: 455px 15px !important;
  border-top-right-radius: 15px 225px !important;
  border-bottom-right-radius: 125px 15px !important;
  border-bottom-left-radius:15px 255px !important;
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
}

.border-style-noborder {
  border-top-left-radius: 455px 15px !important;
  border-top-right-radius: 15px 225px !important;
  border-bottom-right-radius: 225px 15px !important;
  border-bottom-left-radius:15px 255px !important;
  box-shadow: 20px 38px 34px -26px rgba(0, 0, 0, 0.2);
}

body {
  overflow-x: hidden;
}
